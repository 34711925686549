import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteWholeGroupMicrophasePage on WholeGroupMicrophasePage {
        url
        component
        edition
        grade
        microphase
        breadcrumb
        title
        eyebrow
        summary
        chapters
        curriculumMap
        downloadables {
            ...CompleteDownloadables
        }
        related {
            ...CompleteRelatedTier
        }
    }

    ${CompleteDownloadables}
    ${CompleteRelatedTier}
`;
